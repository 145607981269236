<template>
  <div
    class="relative"
    :ref="
      (el) => {
        if (el) select = el;
      }
    "
  >
    <div class="border rounded border-border-primary flex items-center overflow-hidden w-full">
      <UserIcon class="mx-3.5 h-6.5 fill-current" />
      <input
        class="p-4 w-full border-l border-border-primary text-sm font-semibold focus:outline-none"
        type="text"
        placeholder="Search for a user"
        @focus="toggleOpenState"
        @input="handleSearch"
        :disabled="disabled"
        v-model="input"
      />
    </div>
    <ul
      class="
        absolute
        mt-0.5
        z-10
        w-full
        bg-bg-secondary
        border border-border-primary
        z-2
        text-xs
        xs:text-sm
        left-0
        rounded
        max-h-48
        xs:max-h-52
        text-text-primary
        font-medium
        overflow-y-auto
        drop-down-shadow
      "
      v-if="openState && (searchResponse.isLoading || searchResponse.data?.length)"
      :ref="
        (el) => {
          if (el) selectDrop = el;
        }
      "
    >
      <li class="py-3.5" v-if="searchResponse.isLoading">
        <BButtonLoader class="mx-auto h-5 w-5" />
      </li>
      <template v-else>
        <li v-for="item in searchResponse.data" :key="item.id">
          <slot :item="item" />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { ref, onBeforeUnmount, watch } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import debounce from 'lodash.debounce';

export default {
  name: 'BFilterableSelectInput',

  props: {
    label: {
      type: String,
    },

    id: {
      type: String,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    seachMethod: {
      required: true,
      type: Function,
    },

    modelValue: [Number, String],
  },

  setup(props, { emit }) {
    const openState = ref(false);
    const select = ref(null);
    const selectDrop = ref(null);
    const input = ref(props.modelValue);

    let [searchResponse, searchForOptions] = useApi(props.seachMethod);
    searchForOptions = debounce(searchForOptions, 200);

    watch(props, ({ modelValue }) => (input.value = modelValue));

    watch(input, (value) => {
      if (value !== props.modelValue && !props.disabled) {
        emit('update:modelValue', value);
      }
    });

    const handleSearch = () => {
      if (input.value.length >= 3) {
        searchForOptions(input.value);
      }
    };

    const toggleOpenState = () => {
      openState.value = !openState.value;
    };

    const close = ({ target }) => {
      if (!select.value?.contains(target)) {
        openState.value = false;
      } else if (selectDrop.value?.contains(target)) {
        openState.value = false;
      }
    };

    document.addEventListener('click', close);
    onBeforeUnmount(() => {
      document.removeEventListener('click', close);
    });

    return {
      openState,
      select,
      selectDrop,
      input,
      searchResponse,
      options: searchResponse.data,
      handleSearch,
      toggleOpenState,
    };
  },
};
</script>
